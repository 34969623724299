<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="modal fade" id="showJobDetailModal" tabindex="-1" aria-labelledby="showJobDetailModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content mt-5">
          <button type="button" class="modal-cross-btn ms-auto z-1 position-absolute" data-bs-dismiss="modal"
            aria-label="Close">
            <img src="@/assets/images/close.png" alt="" />
          </button>
          <div>
            <h1 class="modal-title fs-5 fw-normal mb-4" id="showJobDetailModalLabel"></h1>
            <div class="row g-0 home-emp-dash-recommends position-relative p-sm-4 p-3">
              <div class="">
                <div class="row">
                  <div class="col-lg-4 d-flex align-items-center">
                    <h6 class="fs-5">{{ item.title }}</h6>
                  </div>
                  <div class="col-7 d-flex ms-auto justify-content-end align-items-center text-nowrap">
                    <div
                      class="recommend-location my-posted-jobs-modal-icon-img d-flex justify-content-center align-items-center">
                      <img class="me-1" src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg" alt="" /><span
                        v-if="item.location">{{
      item.location.name
    }}</span>
                    </div>
                    <div
                      class="recommend-location my-posted-jobs-modal-icon-img mx-3 d-flex justify-content-center align-items-center">
                      <img class="me-2" src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg" alt="" />{{
        item.experience }} years exp.
                    </div>
                    <div
                      class="recommend-location my-posted-jobs-modal-icon-img d-flex justify-content-center align-items-center">
                      <img class="me-2" src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg" alt="" />{{
      item.type }}
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <span>{{ item.description }}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center pt-4 pb-3">
                  <h5 class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold">
                    PKR {{ item.salary }}/mo
                  </h5>
                  <div class="recommend-posted-date-time">
                    <span class="my-posted-jobs-modal-date-time-img d-flex justify-content-center align-items-center">
                      <img class="" src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg" alt="" />Posted {{ item.posted_at }}
                    </span>
                  </div>
                </div>
                <div class="row mt-3 mb-3">
                  <div class="col-md-3 me-2" v-for="media in item.job_media">
                    <img v-if="media.media.type == 'Image'" :src="media.media.file_url"
                      style="width: 100%; border-radius: 16px" />
                  </div>
                </div>
                <div class="row" v-for="media in item.job_media">
                  <audio class="col-md-6" v-if="media.media.type == 'Audio'" controls
                    :src="media.media.file_url"></audio>
                </div>
                <div class="row" v-if="item.status == 'Draft'">
                  <div class="col-md-3">
                    <button class="home-emp-edit-profile-btn" @click="updateJobStatus(item.encrypted_id)">
                      Post Job
                    </button>
                  </div>
                </div>
                <div class="row" v-if="type == 'worker'">
                  <div class="col-md-3">
                    <button class="home-emp-edit-profile-btn" data-bs-toggle="modal" data-bs-target="#applyJob">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="list-review-btn button row mt-3 mb-3 mx-2 gap-md-2 justify-content-md-start justify-content-between">
            <div class="col-lg-3 col-md-4 col-6" v-if="item.status != 'Draft'">
              <button class="px-1 w-100" :class="{
      'primary-btn': isApplication,
      'secondary-btn': !isApplication,
    }" @click="showApplications">
                Applications List
              </button>
            </div>
            <div class="col-lg-3 col-md-4 col-6">
              <button class="px-1 w-100" :class="{ 'primary-btn': isReviews, 'secondary-btn': !isReviews }"
                @click="showReviews">
                Reviews
              </button>
            </div>
          </div>
          <div class="row" v-if="isApplication">
            <div class="col-md-12">
              <div class="card" v-for="application in item.applications">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="d-flex justify-content-start align-items-center">
                        <img v-if="application.worker.profile_pic" :src="application.worker.profile_pic.media.file_url"
                          class="profile_pic" />
                        <img v-else src="@/assets/images/avatar.png" class="profile_pic" />
                        <p class="mb-0 ms-3">
                          {{ application.worker.full_name }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                  <div class="row">
                    <h4 v-if="application.comment">Negotiation</h4>
                    <p v-if="application.comment">{{ application.comment }}</p>
                    <h4 v-if="application.rate">Rate {{ application.rate }}</h4>
                  </div>
                  <div class="row gap-2 mt-3 ms-1">
                    <button class="primary-btn col-md-2 d-flex justify-content-center align-items-center"
                      data-bs-dismiss="modal" aria-label="Close" @click="openChat(application.worker)">
                      <img src="@/assets/images/chat.png" alt="" style="width: 15px; height: 15px" />
                      <span class="ms-2">Chat</span>
                    </button>
                    <button class="primary-btn col-md-2 d-flex justify-content-center align-items-center"
                      @click="hire(application.worker_id)" v-if="application.status == 'Applied'">
                      <img src="@/assets/images/hire.png" alt="" style="width: 15px; height: 15px" />
                      <span class="ms-2">Hire Now</span>
                    </button>
                    <button class="success-btn col-md-2 d-flex justify-content-center align-items-center"
                      v-if="application.status == 'Hired'">
                      <img src="@/assets/images/hire.png" alt="" style="width: 15px; height: 15px" />
                      <span class="ms-2">Hired</span>
                    </button>
                    <button class="home-emp-edit-profile-btn" data-bs-toggle="modal" data-bs-target="#feedbackModal"
                      v-if="application.status == 'Completed' && item.feedbacks.length == 0
      " @click="selectWorker(application.worker_id)">
                      Give Review
                    </button>
                    <button class="dark-btn col-md-2" v-if="application.status == 'Hired'"
                        @click="jobDone(application.id)">
                        Job Done
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="isReviews">
            <div class="review-card p-3 mb-3" v-for="feedback in item.feedbacks">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="dash-create-job-pic d-flex align-items-center">
                    <img v-if="feedback.worker && feedback.worker.profile_pic"
                      :src="feedback.worker.profile_pic.media.file_url" class="profile_pic" />
                    <img v-else-if="feedback.employer && feedback.employer.profile_pic
      " :src="feedback.employer.profile_pic.media.file_url" class="profile_pic" />
                    <img v-else src="@/assets/images/avatar.png" class="profile_pic" />
                    <h6 class="ms-2 text-black" v-if="feedback.worker">
                      {{ feedback.worker.full_name }} | Worker
                    </h6>
                    <h6 class="ms-2 text-black" v-if="feedback.employer">
                      {{ feedback.employer.full_name }} | Employer
                    </h6>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="recommend-posted-date-time">
                    <span
                      class="my-posted-jobs-modal-date-time-img d-flex justify-content-center align-items-center text-black">
                      <img class="" src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg" alt="" />Posted {{
      feedback.posted_at }}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <p class="text-black mt-3">{{ feedback.detail }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Feedback modal -->
    <div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content p-3">
          <button type="button" class="modal-cross-btn ms-auto position-relative" data-bs-toggle="modal"
            data-bs-target="#showJobDetailModal">
            <img src="@/assets/images/close.png" alt="" />
          </button>
          <div class="p-3 review-modal">
            <h1 class="modal-title fs-5 fw-normal mb-4" id="feedbackModalLabel">
              Give your Feedback
            </h1>
            <form @submit.prevent="submit">
              <div class="form-group mb-3">
                <label class="mb-2" v-if="translations">{{ translations.data["title"] }} <span class="text-danger">*</span>
                </label>
                <input type="text" class="basic-info-input form-control" placeholder="Title" v-model="feedback.title" />
              </div>
              <div class="form-group">
                <label class="mb-2" v-if="translations">{{ translations.data["details"] }}
                  <span class="text-danger">*</span></label>
                <input type="text" class="basic-info-input form-control" placeholder="Details" v-model="feedback.detail"
                  required />
              </div>
              <div class="row g-0 justify-content-between pb-4 mt-3">
                <div class="col-6 pe-2">
                  <button type="submit" class="basic-info-button modal-save-cancel-btn">
                    Submit
                  </button>
                </div>
                <div class="col-6 ps-2">
                  <button type="button" class="basic-info-skip-button modal-save-cancel-btn" data-bs-toggle="modal"
                    data-bs-target="#showJobDetailModal">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="home-emp-chatbox position-fixed ms-3 z-1 chatbox"
    :class="{ 'chat-close': !showChat }"
  >
    <div
      class="chatbox-bar d-flex justify-content-between align-items-center px-4"
    >
      <h5 class="mb-0 text-light">Chat</h5>
      <img
        src="@/assets/images/Svg/dash-svg/chatbox-toogle-arrow-pic.svg"
        @click="showChat = !showChat"
      />
    </div>
    <div class="chatbox-profile-pics">
      <div class="px-4 py-2">
        <img
          v-if="selectedWorker && selectedWorker.profile_pic"
          src="{{ selectedWorker.profile_pic.file_url }}"
          alt=""
        />
        <img
          v-else
          src="@/assets/images/avatar.png"
          alt=""
        />
        <span class="fw-semibold fs-6 ps-3" v-if="selectedWorker">{{
          selectedWorker.full_name
        }}</span>
      </div>
      <hr class="my-0" />
      <div style="overflow: auto; height: 260px" id="chat-box">
        <div
          v-if="messages.length > 0"
          v-for="message in messages"
          class="px-4 mt-4"
          :class="{ 'direction-rtl': message.user_id == upId }"
        >
          <img
            v-if="
              message.user_profile && message.user_profile.profile_pic
            "
            :src="message.user_profile.profile_pic.media.file_url"
            alt=""
          />
          <img
            v-else
            src="@/assets/images/avatar.png"
            alt=""
          />
          <!-- <span class="pe-3">5m</span> -->
          <span
            class="fs-6 fw-semibold pe-2"
            v-if="message.user_id == upId"
            >You</span
          >
          <span class="fs-6 fw-semibold pe-2 ps-2" v-else>{{
            message.user_profile.full_name
          }}</span>
          <p
            class="d-flex justify-content-center align-items-center position-relative mt-2 flex-column"
            :class="{
              'chat-1': message.user_id == upId,
              'chat-2': message.user_id != upId,
            }"
          >
            <img
              v-if="message.media"
              :src="message.media.file_url"
              style="width: 50%"
              class="d-block"
            />
            <img
              v-if="message.attachment"
              :src="message.attachment"
              style="width: 50%"
              class="d-block"
            />
            <p>{{ message.text }}</p>
          </p>
        </div>
      </div>
      <form @submit.prevent="sendMessage">
        <div class="d-flex justify-content-between mx-4 mt-3">
          <input
            class="chat-typing ps-4"
            type="text"
            placeholder="Type here..."
            v-model="message"
            required
          />
          <div
            class="chat-pin d-flex justify-content-center align-items-center"
          >
            <img
              src="@/assets/images/Svg/dash-svg/chatbox-attachment-pin-pic.svg"
              alt=""
            />
            <input
              type="file"
              ref="file"
              class="position-absolute opacity-0"
              accept="image/jpg, image/jpeg, image/png, audio/mp3"
              style="width: 50px; height: 50px"
              @change="selectFile"
            />
          </div>
          <div
            class="chat-send d-flex justify-content-center align-items-center"
          >
            <button type="submit" class="btn">
              <img
                src="@/assets/images/Svg/dash-svg/chatbox-send-pic.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import { mapGetters } from "vuex";
import { watch } from "vue";

export default {
  props: {
    item: [],
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      type: localStorage.getItem("type"),
      loading: false,
      isApplication: true,
      isReviews: false,
      selectedWorker: null,
      showChat: false,
      feedback: {
        title: null,
        detail: null,
        rating: null,
        job_id: null,
        employer_id: localStorage.getItem("upId"),
        worker_id: null,
      },
      message: null,
      messages: [],
      channelName: null,
      attachment: null,
      attachmentType: null,
      imagePreview: null,
      audioPreview: null,
      chat: false,
      uId: localStorage.getItem("upId"),
      channelName: null,
      pusherChannel: null,
    };
  },
  inject: ["pusherService"],
  mounted(){
    this.channelName = "EmpX." + this.uId;
    // this.pusher();
    watch(
      () => this.messages,
      () => {
        this.scrollToBottom();
      }
    );
  },
  onUnmounted() {
    this.pusherService.unsubscribe(this.channelName);
  },
  methods: {
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.attachment = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let chatBox = document.getElementById("chat-box");
        chatBox.scrollTop = chatBox.scrollHeight;
      });
    },
    sendMessage() {
      const data = {
        conversation_id: this.selectedWorker.conversation_id ?? null,
        user_id: parseInt(this.uId),
        receiver_id: this.selectedWorker.id,
        text: this.message,
        user_profile: this.selectedWorker,
        attachment: this.attachment,
        attachment_type: this.attachmentType,
      };
      this.messages.push(data);
      this.message = null;
      this.scrollToBottom();
      this.$store
        .dispatch("chat/sendMessage", { data })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pusher() {
      this.pusherChannel = this.pusherService.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        if (this.chat == true) {
          this.messages.push(data.data);
          this.scrollToBottom();
        } else {
          this.chatProfiles.forEach((chatProfile) => {
            if (chatProfile.id == data.data.user_profile.id) {
              chatProfile.msgCount += 1;
            }
          });
        }
      });
    },
    openChat(worker){
      this.selectedWorker= worker;
      this.showChat = !this.showChat;
    },
    submit() {
      this.loading = true;
      this.feedback.job_id = this.item.id;
      this.feedback.worker_id = this.selectedWorker;
      this.feedback.feedback_by = 'Employer';
      const data = this.feedback;
      this.$store
        .dispatch("feedback/store", { data })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Feedback successfully submitted.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.feedback.title = null;
          this.feedback.detail = null;
          router.go(0);
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    selectWorker(worker) {
      this.selectedWorker = worker;
    },
    showReviews() {
      this.isApplication = false;
      this.isReviews = true;
    },
    showApplications() {
      this.isApplication = true;
      this.isReviews = false;
    },
    jobDone(Id) {
      this.loading = true;
      const id = Id;
      const data = {
        status: "Completed",
      };
      this.$store
        .dispatch("jobs/endJob", { id, data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Job has been Ended.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          router.go();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    hire(workerId) {
      this.loading = true;
      const data = {
        worker_id: workerId,
      };
      const id = this.item.id;
      this.$store
        .dispatch("jobs/hire", { data, id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Worker has been Hired successfully.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.item.applications.forEach((item) => {
            if (item.worker_id == workerId) {
              item.status = "Hired";
            }
          });
          router.go();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    updateJobStatus(id) {
      this.loading = true;
      const data = {
        status: "Posted",
      };
      this.$store
        .dispatch("jobs/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Job has been saved successfully.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            router.go();
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
<style scoped>
.home-emp-dash-recommends {
  background-color: #fff;
  border-radius: 16px;
}
</style>
