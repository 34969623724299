<template>
  <div>
    <h5 class="home-emp-dash-recommend-heading fw-normal ms-2 mb-sm-3">Jobs</h5>

    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home-tab-pane"
          type="button"
          role="tab"
          aria-controls="home-tab-pane"
          aria-selected="true"
        >
          On Going Jobs
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile-tab-pane"
          type="button"
          role="tab"
          aria-controls="profile-tab-pane"
          aria-selected="false"
        >
          Completed Jobs
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="contact-tab"
          data-bs-toggle="tab"
          data-bs-target="#contact-tab-pane"
          type="button"
          role="tab"
          aria-controls="contact-tab-pane"
          aria-selected="false"
        >
          Applied Jobs
        </button>
      </li>
    </ul>
    <div class="tab-content p-sm-4 p-3" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="home-tab-pane"
        role="tabpanel"
        aria-labelledby="home-tab"
        tabindex="0"
      >
        <div
          class="container mb-3 px-sm-2 px-0"
          v-if="onGoingJobs.length > 0"
          v-for="job in onGoingJobs"
          data-bs-toggle="modal"
          data-bs-target="#showJobDetailModal"
          @click="selectItem(job)"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="dash-create-job-pic d-flex align-items-center">
                <img
                  v-if="job.employer.profile_pic"
                  :src="job.employer.profile_pic.media.file_url"
                  alt=""
                />
                <img v-else src="@/assets/images/avatar.png" alt="" />
                <h4 class="ms-2 mb-0">
                  {{ job.employer.full_name }}'s Job Post
                </h4>
              </div>
            </div>
            <div class="col-sm-3 mb-sm-0 mb-2">
              <h6 class="mb-0 ps-2">{{ job.title }}</h6>
            </div>
            <div
              class="col-sm-9 d-flex ms-auto justify-content-end align-items-center"
            >
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-1"
                  src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                  alt=""
                />{{ job.location.name }}
              </div>
              <div
                class="recommend-location mx-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                  alt=""
                />{{ job.experience }} years exp.
              </div>
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                  alt=""
                />{{ job.type }}
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <span class="text-ellipsis">{{ job.description }}</span>
          </div>
          <div class="d-sm-flex justify-content-start align-items-center mt-3">
            <h5
              class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
            >
              PKR {{ job.salary }}/mo
            </h5>
            <div class="recommend-posted-date-time ps-sm-5">
              <span class="d-flex justify-content-end align-items-center">
                <img
                  class="me-1"
                  src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                  alt=""
                />Posted {{ job.posted_at }}
              </span>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.image_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.image_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
        </div>
        <div v-else>No Jobs Found.</div>
      </div>
      <div
        class="tab-pane fade"
        id="profile-tab-pane"
        role="tabpanel"
        aria-labelledby="profile-tab"
        tabindex="0"
      >
        <div
          class="container mb-3 px-sm-2 px-0"
          v-if="completedJobs.length > 0"
          v-for="job in completedJobs"
          data-bs-toggle="modal"
          data-bs-target="#showJobDetailModal"
          @click="selectItem(job)"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="dash-create-job-pic d-flex align-items-center">
                <img
                  v-if="job.employer.profile_pic"
                  :src="job.employer.profile_pic.media.file_url"
                  alt=""
                />
                <img v-else src="@/assets/images/avatar.png" alt="" />
                <h4 class="ms-2 mb-0">
                  {{ job.employer.full_name }}'s Job Post
                </h4>
              </div>
            </div>
            <div class="col-sm-3 mb-sm-0 mb-2">
              <h6 class="mb-0 ps-2">{{ job.title }}</h6>
            </div>
            <div
              class="col-sm-9 d-flex ms-auto justify-content-end align-items-center"
            >
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-1"
                  src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                  alt=""
                />{{ job.location.name }}
              </div>
              <div
                class="recommend-location mx-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                  alt=""
                />{{ job.experience }} years exp.
              </div>
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                  alt=""
                />{{ job.type }}
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <span class="text-ellipsis">{{ job.description }}</span>
          </div>
          <div class="d-sm-flex justify-content-start align-items-center mt-3">
            <h5
              class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
            >
              PKR {{ job.salary }}/mo
            </h5>
            <div class="recommend-posted-date-time ps-sm-5">
              <span class="d-flex justify-content-end align-items-center">
                <img
                  class="me-1"
                  src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                  alt=""
                />Posted {{ job.posted_at }}
              </span>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.image_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.image_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
        </div>
        <div v-else>No Jobs Found.</div>
      </div>
      <div
        class="tab-pane fade"
        id="contact-tab-pane"
        role="tabpanel"
        aria-labelledby="contact-tab"
        tabindex="0"
      >
        <div
          class="container mb-3 px-sm-2 px-0"
          v-if="appliedJobs.length > 0"
          v-for="job in appliedJobs"
          data-bs-toggle="modal"
          data-bs-target="#showJobDetailModal"
          @click="selectItem(job)"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="dash-create-job-pic d-flex align-items-center">
                <img
                  v-if="job.employer.profile_pic"
                  :src="job.employer.profile_pic.media.file_url"
                  alt=""
                />
                <img v-else src="@/assets/images/avatar.png" alt="" />
                <h4 class="ms-2 mb-0">
                  {{ job.employer.full_name }}'s Job Post
                </h4>
              </div>
            </div>
            <div class="col-sm-3 mb-sm-0 mb-2">
              <h6 class="mb-0 ps-2">{{ job.title }}</h6>
            </div>
            <div
              class="col-sm-9 d-flex ms-auto justify-content-end align-items-center"
            >
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-1"
                  src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                  alt=""
                />{{ job.location.name }}
              </div>
              <div
                class="recommend-location mx-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                  alt=""
                />{{ job.experience }} years exp.
              </div>
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                  alt=""
                />{{ job.type }}
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <span class="text-ellipsis">{{ job.description }}</span>
          </div>
          <div class="d-sm-flex justify-content-start align-items-center mt-3">
            <h5
              class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold"
            >
              PKR {{ job.salary }}/mo
            </h5>
            <div class="recommend-posted-date-time ps-sm-5">
              <span class="d-flex justify-content-end align-items-center">
                <img
                  class="me-1"
                  src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                  alt=""
                />Posted {{ job.posted_at }}
              </span>
            </div>
          </div>
          <div class="row mt-3 mb-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.image_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3 me-2" v-for="media in job.job_media">
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.image_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
        </div>
        <div v-else>No Jobs Found.</div>
      </div>
    </div>

    <ShowJobDetail :item="selectedItem"></ShowJobDetail>
  </div>
</template>
<script>
import ShowJobDetail from "./ShowJobDetail.vue";

export default {
  components: {
    ShowJobDetail,
  },
  data() {
    return {
      onGoingJobs: [],
      completedJobs: [],
      appliedJobs: [],
      selectedItem: [],
    };
  },
  mounted() {
    this.fetchJobs();
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    fetchJobs() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("jobs/getByStatus", { id })
        .then((response) => {
          this.onGoingJobs = response.data.onGoing;
          this.completedJobs = response.data.completed;
          this.appliedJobs = response.data.applied;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
