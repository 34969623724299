import { createStore } from "vuex";
import users from "./modules/users";
import skills from "./modules/skills";
import userProfile from "./modules/userProfile";
import reference from "./modules/reference";
import jobCategories from "./modules/jobCategories";
import jobs from "./modules/jobs";
import applications from "./modules/application";
import locations from "./modules/locations";
import qualifications from "./modules/qualifications";
import experience from "./modules/experience";
import company from "./modules/company";
import translation from "./modules/translation";
import gig from "./modules/gig";
import feedback from "./modules/feedback";
import faq from "./modules/faq";
import setting from "./modules/setting";
import contactUs from "./modules/contactUs";
import notification from "./modules/notification";
import support from "./modules/support";
import chat from "./modules/chat";
import form from "./modules/form";

export default createStore({
  state: {
    translations: null,
    openChat: false,
  },
  getters: {
    getTranslation: (state) => {
      return state.translations;
    },
    getOpenChat: (state) => {
      return state.openChat;
    },
  },
  mutations: {
    updateTranslations(state, payload) {
      state.translations = payload;
    },
    updateOpenChat(state, payload) {
      state.openChat = payload;
    },
  },
  actions: {
    setTranslation({ commit }, payload) {
      commit("updateTranslations", payload);
    },
    setOpenChat({ commit }, payload) {
      commit("updateOpenChat", payload);
    },
  },
  modules: {
    users,
    skills,
    userProfile,
    reference,
    jobCategories,
    jobs,
    applications,
    locations,
    qualifications,
    experience,
    company,
    translation,
    gig,
    feedback,
    faq,
    setting,
    contactUs,
    notification,
    support,
    chat,
    form,
  },
});
