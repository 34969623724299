<template>
  <div class="wrapper">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <Header v-if="user" :profiles="user.profiles"></Header>
    <div id="home-emp-dashboard" class="px-2">
      <div class="container-home-emp-dash mx-auto g-0 h-100">
        <Profile
          v-if="user && profileHide"
          :user="user"
          :employer-profile="employerProfile"
        ></Profile>
        <div class="row g-0 pb-4">
          <div class="col-lg-8 mt-4">
            <router-view> </router-view>
          </div>
          <div
            class="ps-5 mt-4 col-4 d-lg-block d-none"
            v-if="!employerProfile"
          >
            <div class="recent-work">
              <div
                class="d-flex justify-content-between px-xl-4 px-lg-2 px-3 pt-3 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
                style="margin-bottom: 1px"
              >
                <div class="">
                  <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">
                    Recently Worked With
                  </h5>
                </div>
                <!-- <div class="text-end">
                  <span class="text-uppercase">View all</span>
                </div> -->
              </div>
              <div class="outline-box">
                <template v-if="recentEmployers.length > 0">
                  <div
                    class="d-flex justify-content-between align-items-center px-xl-4 px-lg-2 px-4 pb-4 pt-4"
                    v-for="recentEmployer in recentEmployers"
                  >
                    <div class="d-flex align-items-center">
                      <div class="position-relative">
                        <img
                          v-if="recentEmployer.profile_pic"
                          class="profile_pic"
                          :src="recentEmployer.profile_pic.media.file_url"
                        />
                        <img
                          v-else
                          class="profile_pic"
                          src="@/assets/images/avatar.png"
                        />
                      </div>
                      <div class="ps-xl-3 ps-lg-2 ps-3">
                        <h6 class="mb-0 primary-text-color">
                          {{ recentEmployer.full_name }}
                        </h6>
                      </div>
                    </div>
                    <div class="text-end">
                      <router-link
                        class="text-dark recommend-posted-date-time link-offset-2 text-underline"
                        v-if="type == 'worker'"
                        :to="
                          '/dashboard/employerProfile/' +
                          recentEmployer.encrypted_user_id +
                          '/' +
                          recentEmployer.encrypted_id
                        "
                        target="_blank"
                        >View Profile</router-link
                      >
                      <router-link
                        class="text-dark recommend-posted-date-time link-offset-2 text-underline"
                        v-if="type == 'employer'"
                        :to="
                          '/dashboard/workerProfile/' +
                          recentEmployer.encrypted_id +
                          '/' +
                          recentEmployer.encrypted_user_id
                        "
                        target="_blank"
                        >View Profile</router-link
                      >
                    </div>
                  </div>
                </template>
                <div v-else class="p-3 primary-text-color">
                  <p v-if="type == 'worker'">No recent employer found.</p>
                  <p v-if="type != 'worker'">No recent worker found.</p>
                </div>
              </div>
            </div>

            <!-- Home-emp-Message -->
            <ChatList
              :chat-profiles="chatProfiles"
              :pusher-data="pusherData"
            ></ChatList>
          </div>

          <!-- Feedback and References -->
          <div
            class="col-md-4 ps-3 d-lg-block d-none mt-4 recent-work-nav-tabs rounded-4"
            v-if="employerProfile"
          >
            <ul
              class="nav nav-tabs header-bg rounded-top-4 fw-semibold"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="feedback-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#feedback-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="feedback-tab-pane"
                  aria-selected="true"
                >
                  Feedbacks
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="reference-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#reference-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="reference-tab-pane"
                  aria-selected="false"
                >
                  References
                </button>
              </li>
            </ul>
            <hr class="my-0" />
            <div class="tab-content py-4 px-3">
              <div
                class="tab-pane fade show active"
                id="feedback-tab-pane"
                role="tabpanel"
                aria-labelledby="feedback-tab"
                tabindex="0"
              >
                <template v-if="feedbacks.length > 0">
                  <div
                    class="card mb-2 primary-bg-color text-white rounded-5"
                    v-for="feedback in feedbacks"
                  >
                    <div class="card-body">
                      <img
                        v-if="feedback.worker && feedback.worker.profile_pic"
                        :src="feedback.worker.profile_pic.media.file_url"
                        class="profile_pic"
                      />
                      <img
                        v-else-if="
                          feedback.employer && feedback.employer.profile_pic
                        "
                        :src="feedback.employer.profile_pic.media.file_url"
                        class="profile_pic"
                      />
                      <img
                        v-else
                        src="@/assets/images/avatar.png"
                        class="profile_pic"
                      />
                      <p class="m-0">
                        <strong v-if="feedback.worker">{{
                          feedback.worker.full_name
                        }}</strong>
                        <strong v-if="feedback.employer">{{
                          feedback.employer.full_name
                        }}</strong>
                      </p>
                      <p class="m-0">{{ feedback.detail }}</p>
                      <span class="m-0 small"
                        >Posted {{ feedback.posted_at }}</span
                      >
                    </div>
                  </div>
                </template>
                <p v-else>No feedback available.</p>
              </div>
              <div
                class="tab-pane fade"
                id="reference-tab-pane"
                role="tabpanel"
                aria-labelledby="reference-tab"
                tabindex="0"
              >
                <template v-if="references.length > 0">
                  <div
                    class="card mb-2 primary-bg-color text-white rounded-5"
                    v-for="reference in references"
                  >
                    <div class="card-body">
                      <p class="m-0 mb-1">
                        <strong>{{ reference.f_name }}</strong> |
                        <small> {{ reference.designation }} </small>
                      </p>
                      <table class="table">
                        <tr>
                          <td>Phone Number:</td>
                          <td>{{ reference.phone }}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{{ reference.email }}</td>
                        </tr>
                        <tr>
                          <td>Address:</td>
                          <td>{{ reference.address }}</td>
                        </tr>
                        <tr>
                          <td>Relation:</td>
                          <td>{{ reference.relation }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </template>
                <p v-else>No reference available.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Profile from "./Profile.vue";
import { watch } from "vue";
import ChatList from "./ChatList.vue";

export default {
  components: {
    Header,
    Footer,
    Profile,
    ChatList,
  },
  data() {
    return {
      loading: false,
      user: null,
      profileImage: null,
      skills: [],
      userId: null,
      upId: null,
      profileHide: true,
      recentEmployers: [],
      employerProfile: false,
      references: [],
      feedbacks: [],
      type: localStorage.getItem("type"),
      isUpId: false,
      chatProfiles: [],
      chat: false,
      message: null,
      messages: [],
      channelName: null,
      selectedUser: null,
      attachment: null,
      attachmentType: null,
      imagePreview: null,
      audioPreview: null,
      pusherChannel: null,
      pusherData: null,
    };
  },
  watch: {
    $route(to, from) {
      this.fetchData();
      this.fetchUser();
    },
  },
  inject: ["pusherService"],
  mounted() {
    this.upId = localStorage.getItem("upId");
    this.userId = localStorage.getItem("uid");
    this.channelName = "EMPX." + this.upId;
    this.pusher();
    this.profileShow();
    this.fetchData();
    this.fetchUser();
    localStorage.removeItem("cnic");
    this.fetchChatProfiles();
    watch(
      () => this.messages,
      () => {
        this.scrollToBottom();
      }
    );
  },
  onUnmounted() {
    this.pusherService.unsubscribe(this.channelName);
  },
  methods: {
    pusher() {
      this.pusherChannel = this.pusherService.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        this.pusherData = data;
        this.$toast.show(
          "New Message from " + data.data.user_profile.full_name,
          {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          }
        );
      });
    },
    fetchChatProfiles() {
      const data = localStorage.getItem("upId");
      this.$store
        .dispatch("chat/userProfiles", { data })
        .then((response) => {
          this.chatProfiles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchUser() {
      if (this.$route.params.id) {
        this.userId = this.$route.params.id;
        if (localStorage.getItem("type") == "worker") {
          this.type = "employer";
        } else if (localStorage.getItem("type") == "employer") {
          this.type = "worker";
        }
      } else {
        this.userId = localStorage.getItem("uid");
        this.type = localStorage.getItem("type");
      }
      this.userMe();
    },
    userMe() {
      this.loading = true;
      const id = this.userId;
      if (this.$route.query.type && this.$route.query.type == "Company") {
        this.type = this.$route.query.type;
      }
      const type = this.type;
      this.$store
        .dispatch("userProfile/getSingle", { id, type })
        .then((response) => {
          this.loading = false;
          this.user = response.data;
          if (this.user.profile_pic) {
            localStorage.setItem(
              "profilePic",
              this.user.profile_pic.media.file_url
            );
          }
          if (this.$route.params.id) {
            this.employerProfile = true;
          } else {
            this.employerProfile = false;
            localStorage.setItem("upId", this.user.id);
            this.isUpId = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchData() {
      if (this.$route.params.id) {
        if (this.type == "worker") {
          this.employerProfile = true;
          this.fetchEmployerFeedbackReference();
        } else if (this.type == "employer") {
          this.employerProfile = true;
          this.fetchWorkerFeedbackReference();
        }
      } else {
        this.employerProfile = false;
        if (this.type == "worker") {
          this.fetchRecentEmployers();
        } else if (this.type == "employer") {
          this.fetchRecentWorkers();
        }
      }
    },
    profileShow() {
      if (screen.width <= 992) {
        this.profileHide = false;
      }
    },
    fetchEmployerFeedbackReference() {
      const employerId = this.$route.params.empId;
      const workerId = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getEmployerFeedbackReference", {
          workerId,
          employerId,
        })
        .then((response) => {
          this.references = response.data.references;
          this.feedbacks = response.data.feedbacks;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchWorkerFeedbackReference() {
      const workerId = this.$route.params.id;
      const employerId = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getWorkerFeedbackReference", {
          employerId,
          workerId,
        })
        .then((response) => {
          this.references = response.data.references;
          this.feedbacks = response.data.feedbacks;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchRecentEmployers() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getRecentEmployers", { id })
        .then((response) => {
          this.recentEmployers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchRecentWorkers() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getRecentWorkers", { id })
        .then((response) => {
          this.recentEmployers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.container {
  top: -101px;
}

.wrapper {
  background: #fff;
}

.recent-work-nav-tabs .nav-tabs .nav-link {
  background-color: transparent !important;
  border: none !important;
  padding: 20px 20px 15px 20px !important;
}

.recent-work-nav-tabs .nav-tabs .nav-link.active {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 17px !important;
}

.table {
  font-size: 12px !important;
}

.table tr td:nth-child(2) {
  color: rgba(213, 213, 213, 1) !important;
}
</style>
