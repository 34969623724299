<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>

  <div
    class="home-emp-chatbox position-fixed ms-3 z-1 chatbox"
    :class="{ 'chat-close': !openChat.data }"
  >
    <div
      class="chatbox-bar d-flex justify-content-between align-items-center px-4"
    >
      <h5 class="mb-0 text-light">Chat</h5>
      <img
        src="@/assets/images/Svg/dash-svg/chatbox-toogle-arrow-pic.svg"
        @click="closeChat"
      />
    </div>
    <div class="chatbox-profile-pics">
      <div class="px-4 py-2">
        <img
          v-if="user?.profile_pic"
          :src="user.profile_pic.media.file_url"
          alt=""
        />
        <img v-else src="@/assets/images/avatar.png" alt="" />
        <span class="fw-semibold fs-6 ps-3" v-if="user">{{
          user.full_name
        }}</span>
      </div>
      <hr class="my-0" />
      <div style="overflow: auto; height: 260px" id="chat-box">
        <div
          v-if="messages.length > 0"
          v-for="message in messages"
          class="px-4 mt-4"
          :class="{ 'direction-rtl': message.user_id == upId }"
        >
          <img
            v-if="message.user_profile && message.user_profile.profile_pic"
            :src="message.user_profile.profile_pic.media.file_url"
            alt=""
          />
          <img v-else src="@/assets/images/avatar.png" alt="" />
          <!-- <span class="pe-3">5m</span> -->
          <span class="fs-6 fw-semibold pe-2" v-if="message.user_id == upId"
            >You</span
          >
          <span class="fs-6 fw-semibold pe-2 ps-2" v-else>{{
            message.user_profile.full_name
          }}</span>
          <p
            class="d-flex justify-content-center align-items-center position-relative mt-2 flex-column"
            :class="{
              'chat-1': message.user_id == upId,
              'chat-2': message.user_id != upId,
            }"
          >
            <img
              v-if="message.media"
              :src="message.media.file_url"
              style="width: 50%"
              class="d-block"
            />
            <img
              v-if="message.attachment"
              :src="message.attachment"
              style="width: 50%"
              class="d-block"
            />
            <span>{{ message.text }}</span>
          </p>
        </div>
      </div>
      <form @submit.prevent="sendMessage">
        <div class="d-flex justify-content-between mx-4 mt-3">
          <input
            class="chat-typing ps-4"
            type="text"
            placeholder="Type here..."
            v-model="message"
            required
          />
          <div
            class="chat-pin d-flex justify-content-center align-items-center"
          >
            <img
              src="@/assets/images/Svg/dash-svg/chatbox-attachment-pin-pic.svg"
              alt=""
            />
            <input
              type="file"
              ref="file"
              class="position-absolute opacity-0"
              accept="image/jpg, image/jpeg, image/png, audio/mp3"
              style="width: 50px; height: 50px"
              @change="selectFile"
            />
          </div>
          <div
            class="chat-send d-flex justify-content-center align-items-center"
          >
            <button type="submit" class="btn">
              <img
                src="@/assets/images/Svg/dash-svg/chatbox-send-pic.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    selectedUser: null,
    pusherData: null,
  },
  data() {
    return {
      loading: false,
      messages: [],
      user: null,
      upId: localStorage.getItem("upId"),
      message: null,
      attachment: null,
      attachmentType: null,
    };
  },
  computed: {
    ...mapGetters({
      openChat: "getOpenChat",
    }),
  },
  watch: {
    selectedUser(newChat) {
      this.loading = true;
      this.user = newChat.user_profile;
      this.messages = [];
      const data = {
        conversation_id: newChat.conversation_id,
      };
      this.$store
        .dispatch("chat/userMessages", { data })
        .then((response) => {
          this.messages = response.data;
          this.loading = false;
          this.scrollToBottom();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    pusherData(newMessage) {
      if (newMessage) {
        this.messages.push(newMessage.data);
        this.scrollToBottom();
      }
    },
  },
  methods: {
    sendMessage() {
      const data = {
        conversation_id: this.selectedUser.conversation_id,
        user_id: parseInt(this.upId),
        receiver_id: this.selectedUser.user_id,
        text: this.message,
        user_profile: null,
        attachment: this.attachment,
        attachment_type: this.attachmentType,
      };
      this.messages.push(data);
      this.message = null;
      this.scrollToBottom();
      this.$store
        .dispatch("chat/sendMessage", { data })
        .then((response) => {})
        .catch((e) => {
          console.log(e);
        });
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.attachment = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    closeChat() {
      this.$store.dispatch("setOpenChat", false);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let chatBox = document.getElementById("chat-box");
        chatBox.scrollTop = chatBox.scrollHeight;
      });
    },
  },
};
</script>
