<template>
  <div>
    <div id="recommendations">
      <!-- Recommendations -->
      <div class="row w-75">
        <h5 class="home-emp-dash-recommend-heading mt-sm-4 mb-sm-1 mb-0">
          Skill-set
        </h5>
      </div>
      <!-- Recommendations-Cards -->

      <template v-for="gig in gigs">
        <div
          class="row g-0 home-emp-dash-recommends mt-4"
          data-bs-toggle="modal"
          data-bs-target="#showGigDetailModal"
          @click="selectGig(gig)"
        >
          <div class="container p-sm-4 p-3">
            <div class="row g-0 gigs-card">
              <div class="col-sm-6 mb-sm-0 mb-2">
                <div class="row g-0 justify-content-between ms-sm-3 mt-3">
                  <div class="col-3">
                    <h6>{{ gig.title }}</h6>
                  </div>
                </div>
                <div class="row g-0 ms-sm-3 mt-2 pe-5">
                  <p class="text-ellipsis">
                    {{ gig.details }}
                  </p>
                </div>
                <span class="skill-tag" v-if="gig.skill">{{
                  gig.skill.name
                }}</span>
                <div class="row g-0 mt-4 ms-sm-3">
                  <h5
                    class="home-emp-dash-recommend-heading fw-bold col-md-6 primary-text-color"
                  >
                    {{ gig.rate }} pkr
                  </h5>
                  <span
                    class="d-flex justify-content-start align-items-center col-md-6"
                  >
                    <img
                      class="me-1"
                      src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                      alt=""
                    />Posted
                    {{ gig.posted_at }}
                  </span>
                </div>
                <!-- <div class="recommend-posted-date-time ms-sm-3 mt-5 pt-3"></div> -->
              </div>
              <div class="col-sm-6 text-center d-flex align-items-center">
                <div class="gig-img-border-radius">
                  <img
                    v-if="gig.cover_image"
                    :src="gig.cover_image.media.file_url"
                    alt=""
                    style="width: 100%"
                  />
                  <img
                    v-else
                    src="@/assets/images/Svg/dash-svg/recommend-carpenter-pic.svg"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <ShowGigDetail :item="selectedGig"></ShowGigDetail>
  </div>
</template>
<script>
import ShowGigDetail from "../Worker/ShowGigDetail.vue";

export default {
  components: {
    ShowGigDetail,
  },
  data() {
    return {
      gigs: [],
      selectedGig: [],
      workerId: null,
    };
  },
  mounted() {
    this.workerId = this.$route.params.id;
    this.fetchGigs();
  },
  methods: {
    selectGig(gig) {
      this.selectedGig = gig;
    },
    fetchGigs() {
      this.loading = true;
      const id = this.workerId;
      this.$store
        .dispatch("gig/getAll", { id })
        .then((response) => {
          this.gigs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
