<template>
  <div class="recent-work mt-4">
    <div
      class="d-flex justify-content-between px-xl-4 px-lg-2 px-4 pt-4 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
      style="margin-bottom: 1px"
    >
      <div class="">
        <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">Message</h5>
      </div>
      <!-- <div class="text-end">
                  <span class="text-uppercase">View all</span>
                </div> -->
    </div>
    <div class="outline-box">
      <template v-if="chatProfiles.length > 0">
        <div
          class="d-flex justify-content-between align-items-center px-xl-4 px-lg-2 px-4 pb-4 pt-4"
          v-for="profile in chatProfiles"
          @click="openChat(profile)"
        >
          <div class="d-flex align-items-center">
            <div class="position-relative">
              <img
                v-if="profile.user_profile.profile_pic"
                class="recent-work-img"
                :src="profile.user_profile.profile_pic.media.file_url"
                alt=""
              />
              <img
                v-else
                class="recent-work-img"
                src="@/assets/images/avatar.png"
                alt=""
              />
            </div>
            <div class="ps-xl-3 ps-lg-2 ps-3">
              <h6 class="mb-0 primary-text-color">
                {{ profile.user_profile.full_name }}
              </h6>
            </div>
          </div>
          <div class="text-end">
            <span
              class="recent-work-msg-notification fw-semibold ms-3"
              v-if="profile.msgCount"
              >{{ profile.msgCount }}</span
            >
          </div>
        </div>
      </template>
      <div v-else class="p-4 outline-box primary-text-color">
        No chat found.
      </div>
    </div>
    <ChatBox
      :selected-user="selectedUser"
      :pusher-data="pusherResponse"
    ></ChatBox>
  </div>
</template>

<script>
import ChatBox from "./ChatBox.vue";

export default {
  components: {
    ChatBox,
  },
  props: {
    chatProfiles: null,
    pusherData: null,
  },
  watch: {
    pusherData(newData) {
      if (newData) {
        this.pusherResponse = newData;
      }
    },
  },
  data() {
    return {
      loading: false,
      selectedUser: null,
      pusherResponse: null,
    };
  },
  mounted() {
    this.chatProfiles.forEach((chatProfile) => {
      if (this.pusherData != null) {
        if (chatProfile.user_id == this.pusherData.data.user_id) {
          chatProfile.msgCount += 1;
        }
      }
    });
  },
  methods: {
    openChat(profile) {
      this.selectedUser = profile;
      const data = true;
      this.$store.dispatch("setOpenChat", { data });
    },
  },
};
</script>
